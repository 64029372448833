exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-analyze-index-js": () => import("./../../../src/pages/analyze/index.js" /* webpackChunkName: "component---src-pages-analyze-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-index-js": () => import("./../../../src/pages/learn/index.js" /* webpackChunkName: "component---src-pages-learn-index-js" */),
  "component---src-pages-predict-index-js": () => import("./../../../src/pages/predict/index.js" /* webpackChunkName: "component---src-pages-predict-index-js" */)
}

